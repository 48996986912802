import PropTypes from "prop-types";
import React from "react";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";
import { ThemeContext } from "../layouts";

const NotFoundPage = () => {
  return (
  <React.Fragment>
    <ThemeContext.Consumer>
      { theme => (
        <Article theme={theme}>
          <header>
            <Headline title="NOT FOUND" theme={theme} />
          </header>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Article>
      )}
    </ThemeContext.Consumer>
  </React.Fragment>
  );
};

export default NotFoundPage;
